import React from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import profile from "../../../../Images/profile.png"
import attach from "../../../../Images/attach.svg"
import phone from "../../../../Images/phone.png"
import dollar from "../../../../Images/dollar_icon.svg";
import online from "../../../../Images/online_icon.png"
import dollarCircle from "../../../../Images/dollar-circle.png"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import smiley from "../../../../Images/workspace_icons/smiley.png"
import arrow from "../../../../Images/workspace_icons/arrow.png"
import attachImg from "../../../../Images/workspace_icons/attach.png"
import phoneImg from "../../../../Images/workspace_icons/phone.png"
import message from "../../../../Images/workspace_icons/message.png"
import desktop from "../../../../Images/workspace_icons/desktop.png"


import "./MyWorkspace.css"

const MyWorkspace = ({role}) => {
  return (
    <>
    {role === "client" ? (
      <div>My client MyWorkspace</div>
    ) : (
     <div className='my-workspace'>
      <div>
        <h3>My Workspace</h3>
      </div>
      <p>Lorem ipsum is a dummy or placeholder text commonly used in graphic design</p>
      <Row>
        <Col xs={12} sm={12} md={6} lg={3}>
          <Card>
              <Card.Header>
                <h6 className='mb-0 py-2'>Progress</h6>
              </Card.Header>
              <Card.Body>
                  <div className='d-flex align-items-center'>
                    <div className='profile_pic'>
                    <img src={profile} alt="profile"/>
                    </div>
                    <div className='ms-2 mt-2'>
                      <label>Anil kumar</label>
                      <div className='mt-1'>
                        <img src={attach} alt="image" /> <span>11:30 AM</span>
                      </div>
                    </div>
                  </div>
                  <div class="vertical-line"></div>
                  <div className='d-flex align-items-center'>
                    <div className='profile_pic'>
                    <img src={profile} alt="profile"/>
                    </div>
                    <div className='ms-2 mt-2'>
                      <label>Anil kumar</label>
                      <div className='mt-1'>
                        <img src={phone} alt="image" /> <span>11:30 AM</span>
                      </div>
                    </div>
                  </div>
              </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={9}>
        <Card>
          <Card.Header className='d-flex align-items-center justify-content-between'>
            <div>
              <h6 className='mb-0'>Mobile App Design</h6>
            </div>
            <div className='btns-wrap'>
              <button type="button" className='secondary-btn'>Dispute</button>
              <button type="button" className='secondary-btn ms-3'>Wallet</button>
            </div>
          </Card.Header>
          <Card.Body>
          <div class="mywork-grid-container">
              <div class="grid-item">
                <label>Freelancer</label>
                <div className='d-flex align-items-center'>
                    <div className='profile_pic position-relative'>
                    <img src={profile} alt="profile"/>
                    <div className='online_icon'>
                        <img src={online} alt="image"/>
                    </div>
                    </div>
                    <div className='ms-2'>
                      <h6>Anil kumar</h6>                      
                    </div>
                  </div>
              </div>
              <div class="grid-item">
                <label>Client</label>
                <div className='d-flex align-items-center'>
                    <div className='profile_pic position-relative'>
                    <img src={profile} alt="profile"/>
                    <div className='online_icon'>
                        <img src={online} alt="image"/>
                    </div>
                    </div>
                    <div className='ms-2'>
                      <h6>Anil kumar</h6>                      
                    </div>
                  </div>
              </div>
              <div class="grid-item">
                <label>Bid Date</label>
                <div>
                   <h6>15-11-2024</h6>
                </div>
              </div>
              <div class="grid-item">
                <label>Timeline</label>
                <div>
                   <h6>4/1 Delivery time</h6>
                </div>
              </div>
              <div class="grid-item">
                <label>ID-#FL012024</label>
                <div>
                   <label>Payment mode <img src={dollar} alt="dollar"/></label>
                </div>
              </div>
            </div>
            <div className='d-flex mt-3'>
            <div>
              <label>Payment</label>
              <div className='rounded_btn'>
                <img src={dollarCircle} /><span className='ms-2'>$ 500</span>
              </div>
            </div>
            <div className='ms-3'>
              <label>Due Time</label>
              <div className='rounded_btn'>
                <img src={dollarCircle} /><span className='ms-2'>02:24:45</span>
              </div>
            </div>
            </div>
            <p className='mt-3'>Lorem ipsum is a dummy or placeholder text commonly used in graphic design Lorem ipsum is a dummy or placeholder text commonly used in graphic designLorem ipsum is a dummy or placeholder text commonly used in graphic designLorem.</p>
            <p>Link <a>https://www.google.co.in/</a></p>

            <div className='msg-wrap'>
              <div className='left-wrap'>
              <InputGroup>
                <Form.Control
                  placeholder="Type a new message here"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                  <img src={smiley} alt="image"/>
                  <img src={arrow} alt="image" className='ms-3'/>
                </InputGroup.Text>
              </InputGroup>
              </div>
              <div className='right-wrap'>
              <img src={attachImg} alt="image"/>
              <img src={phoneImg} alt="image" className='ms-3'/>
              <img src={message} alt="image" className='ms-3'/>
              <img src={desktop} alt="image" className='ms-3'/>
              </div>
            </div>
          </Card.Body>
        </Card>
        </Col>
      </Row>
     </div>
    
    )}
  </>
  )
}

export default MyWorkspace


