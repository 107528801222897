import React, { useEffect, useState } from "react";
import ProjectPreview from "./ProjectPreview";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import closeIcon from "../../../Images/icons/close-x.svg";

import { Row, Col, Card } from "react-bootstrap";
import "./Project.css";
import "./ProjectSkills.css";
import { Range } from "react-range";
import {
  formatDateToDDMMYYYYC,
  formatForInput,
} from "../../Common/utils/utils";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";

const ProjectSkills = ({
  payloadData,
  setPayloadData,
  handleBack,
  videoSrc,
  userDetails,
  userId,
  projectId,
  certificateFile,
  projectData,
  type,
}) => {
  const [showProPrev, setShowProPrev] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notify } = useToast();

  const [skillPayloadData, setSkillPayloadData] = useState({
    skills: "",
    skillsNeeded: [],
    tier: [],
    serviceType: [],
    estimateBudget: {
      min: 0, // Minimum budget
      max: 0, // Maximum budgets
    },
    acceptedPaymentModes: "",
    paymentType: {
      type: "Hours",
      duration: 0,
    },
    projectBidTimings: {
      start: "",
      end: "",
    },
  });
  const [skillList, setSkillList] = useState([]);

  const handleBidTimingChange = (e) => {
    const { id, value } = e.target;
    setSkillPayloadData((prevState) => ({
      ...prevState,
      projectBidTimings: {
        ...prevState.projectBidTimings,
        [id]: value,
      },
    }));
  };

  const handleProSkillNext = (boolean) => {
    setShowProPrev(boolean);
    handlePost();
  };

  const handlePost = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        title: payloadData?.projectName,
        description: payloadData?.description,
        category: payloadData?.category,
        subCategory: payloadData?.subCategory,
        userId: userId,
        tags: payloadData?.searchTagsList,
        skillsNeeded: skillPayloadData?.skillsNeeded,
        instruction: payloadData?.instruction,
        media: [
          {
            coverImage: payloadData?.coverPhoto,
            video: payloadData?.video,
            photo: payloadData?.profilePhoto,
            document: payloadData?.certificateFile,
          },
        ],
        paymentType: {
          type: skillPayloadData?.paymentType?.type,
          duration: skillPayloadData?.paymentType?.duration,
        },
        estimateBudget: {
          min: skillPayloadData?.estimateBudget?.min,
          max: skillPayloadData?.estimateBudget?.max,
        },
        projectBidTimings: {
          start: formatDateToDDMMYYYYC(
            skillPayloadData?.projectBidTimings?.start
          ),
          end: formatDateToDDMMYYYYC(skillPayloadData?.projectBidTimings?.end),
        },
        acceptedPaymentModes: skillPayloadData?.acceptedPaymentModes,
        serviceType: skillPayloadData?.serviceType,
        tier: skillPayloadData?.tier,
        status: "draft",
        projectStatus: "Open",
        projectId: type === "draft" ? projectData?.projectId : projectId,
      };

      // console.log(payload, "project post payload");

      // Make the API call
      const response = await ApiCall(
        "PUT",
        "project",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        notify("success", "Project updated successfully!");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  const handleskillChange = (e) => {
    setSkillPayloadData({ ...skillPayloadData, skills: e.target.value });
  };

  const handleDeleteSkill = (index) => {
    setSkillList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    const trimmedTag = skillPayloadData.skills.trim();

    if (e.key === "Enter" && trimmedTag) {
      e.preventDefault();

      if (!skillList.includes(trimmedTag)) {
        setSkillList((prev) => [...prev, trimmedTag]);
      }

      setSkillPayloadData({ ...skillPayloadData, skills: "" });
    }
  };

  useEffect(() => {
    setSkillPayloadData((prev) => ({ ...prev, skillsNeeded: skillList }));
  }, [skillList]);

  const handleMultiSelect = (field, value) => {
    setSkillPayloadData((prev) => {
      const isSelected = prev[field].includes(value);
      return {
        ...prev,
        [field]: isSelected
          ? prev[field].filter((item) => item !== value) // Remove if already selected
          : [...prev[field], value], // Add if not selected
      };
    });
  };
  const STEP = 10; // Increment steps
  const MIN = 0; // Minimum value
  const MAX = 1000; // Maximum value

  const handleRangeChange = (values) => {
    // console.log(values,"values");

    setSkillPayloadData((prev) => ({
      ...prev,
      estimateBudget: {
        min: values[0],
        max: values[1],
      },
    }));
  };

  const handlePaymentModeChange = (e) => {
    const selectedMode = e.target.value;

    // Update skillPayloadData with the selected payment mode
    setSkillPayloadData((prev) => ({
      ...prev,
      acceptedPaymentModes: selectedMode,
    }));
  };

  const handlePaymentTypeChange = (type) => {
    setSkillPayloadData((prev) => ({
      ...prev,
      paymentType: { ...prev.paymentType, type },
    }));
  };

  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value, 10) || 0;
    setSkillPayloadData((prev) => ({
      ...prev,
      paymentType: { ...prev.paymentType, duration },
    }));
  };

  // console.log(skillPayloadData, "skillPayloadData");

  return (
    <>
      {!showProPrev ? (
        <>
          <div className="project_skills">
            <div>
              <h5 className="mt-2">What skills are required?</h5>
            </div>
            <Form.Label>
              Add skills that Freelancers should have to work on your project
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Select skills"
              value={skillPayloadData.skills}
              onChange={handleskillChange}
              onKeyDown={handleKeyDown}
            />
            {/* <Form.Select aria-label="Select Skills*">
              <option>Select Skills</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}

            <div className="tagList">
              <ul>
                {skillList.map((tag, index) => (
                  <li key={index}>
                    <span>{tag}</span>
                    <span onClick={() => handleDeleteSkill(index)}>
                      <img
                        src={closeIcon}
                        alt="Delete Icon"
                        className="delete-icon"
                      />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div>
                <h5>Select Your Tire</h5>
              </div>
              {/* <Card>
                <p>
                  Lorem ipsum is a dummy or placeholder text commonly used in
                  graphic design, publishing, and web development to fill empty
                  spaces in a layout that do not yet have content.
                </p>
                <div className="tagList">
                  <ul className="justify-content-start ps-0">
                    <li>Beginner</li>
                    <li>Emerging</li>
                    <li>Standard</li>
                    <li>Proficient</li>
                    <li>Elite</li>
                  </ul>
                </div>
              </Card> */}

              <Card>
                <p>
                  Lorem ipsum is a dummy or placeholder text commonly used in
                  graphic design, publishing, and web development to fill empty
                  spaces in a layout that do not yet have content.
                </p>
                <div className="tagList">
                  <ul className="justify-content-start ps-0">
                    {[
                      "Beginner",
                      "Emerging",
                      "Standard",
                      "Proficient",
                      "Elite",
                    ].map((tier) => (
                      <li
                        key={tier}
                        onClick={() => handleMultiSelect("tier", tier)}
                        // style={{
                        //   cursor: "pointer",
                        //   fontWeight: skillPayloadData.tier.includes(tier)
                        //     ? "bold"
                        //     : "normal",
                        //   textDecoration: skillPayloadData.tier.includes(tier)
                        //     ? "underline"
                        //     : "none",
                        // }}

                        style={{
                          cursor: "pointer",
                          fontWeight: skillPayloadData.tier.includes(
                            tier
                          )
                            ? ""
                            : "normal",
                          textDecoration:
                          skillPayloadData.tier.includes(tier)
                              ? ""
                              : "none",
                          background: skillPayloadData.tier.includes(
                            tier
                          )
                            ? "#F0806C"
                            : "#fff",
                          color: skillPayloadData.tier.includes(
                            tier
                          )
                            ? "#fff"
                            : "#000",
                        }}


                      >
                        {tier}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
            </div>
            <div className="payment_section">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <div>
                    <h5>
                      How much do you want to pay each Freelancer to complete
                      this task?
                    </h5>
                  </div>
                  {/* <Card>
                    <p>You may change this at any time.</p>
                    <h6>By Amount range</h6>
                    <Form.Range />
                    <div className="tagList">
                      <ul className="justify-content-between ps-0 mb-0">
                        <li className="pe-2">$20</li>
                        <li className="pe-2">$500</li>
                      </ul>
                    </div>
                  </Card> */}

                  <Card>
                    <p>You may change this at any time.</p>
                    <h6>By Amount Range</h6>

                    {/* Range Slider */}
                    <div style={{ padding: "20px" }}>
                      <Range
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        values={[
                          skillPayloadData.estimateBudget.min,
                          skillPayloadData.estimateBudget.max,
                        ]}
                        onChange={handleRangeChange}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "6px",
                              width: "100%",
                              background: "#ddd",
                            }}
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props, index }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "16px",
                              width: "16px",
                              borderRadius: "50%",
                              backgroundColor: "#f0806c",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "-28px",
                                color: "#555",
                                fontWeight: "bold",
                                fontSize: "12px",
                                background: "white",
                                padding: "2px 4px",
                                borderRadius: "3px",
                              }}
                            >
                              {index === 0
                                ? skillPayloadData.estimateBudget.min
                                : skillPayloadData.estimateBudget.max}
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    <div className="tagList">
                      <ul className="justify-content-between ps-0 mb-0">
                        <li className="pe-2">
                          ${skillPayloadData.estimateBudget.min}
                        </li>
                        <li className="pe-2">
                          ${skillPayloadData.estimateBudget.max}
                        </li>
                      </ul>
                    </div>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <div>
                    <h5>Choose Payment Mode</h5>
                  </div>
                  <Card>
                    <p>
                      This is the currency your Freelancers will be paid in. You
                      may change this at any time.
                    </p>
                    <Form.Select
                      aria-label="Select Payment mode"
                      className="mt-4 mb-3"
                      onChange={handlePaymentModeChange} // Call the handler on selection
                      value={skillPayloadData.acceptedPaymentModes} // Set selected value
                    >
                      <option value="">Select Payment mode</option>
                      <option value="FREL">Frel</option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="EURO">Euro</option>
                      <option value="POUND">British Pound</option>
                      <option value="BITCOIN">Bitcoin</option>
                      <option value="LITECOIN">Litecoin</option>
                      <option value="ETHEREUM">Ethereum</option>
                      <option value="USDT">USDT (TRC-20)</option>
                    </Form.Select>
                  </Card>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div>
                  <h5>Time to Complete</h5>
                </div>
                <Card className="time_card time_tocomplete_sec">
                  <p>
                    How much time do you want to give Freelancers to complete
                    your project? Please consider all of your requirements and
                    be sure to give your Freelancers plenty of time to work on
                    the project without being rushed.
                  </p>
                  <div>
                    <label className="grey_font">Select</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Text input with dropdown button"
                        placeholder="Enter duration"
                        value={skillPayloadData.paymentType.duration}
                        onChange={handleDurationChange} // Update duration on input change
                      />
                      <DropdownButton
                        variant="outline-secondary"
                        title={
                          skillPayloadData.paymentType.type
                            .charAt(0)
                            .toUpperCase() +
                          skillPayloadData.paymentType.type.slice(1)
                        } // Capitalize the type
                        id="input-group-dropdown-2"
                        align="end"
                        onSelect={handlePaymentTypeChange} // Update payment type on selection
                      >
                        <Dropdown.Item eventKey="Hours">Hours</Dropdown.Item>
                        <Dropdown.Item eventKey="Days">Days</Dropdown.Item>
                        <Dropdown.Item eventKey="Weeks">Weeks</Dropdown.Item>
                        <Dropdown.Item eventKey="Months">Months</Dropdown.Item>
                        <Dropdown.Item eventKey="Years">Years</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div>
                  <h5>Biding Time </h5>
                </div>
                <Card className="time_card biding_time_sec">
                  <p>Select bid start date and end date</p>
                  <Row className="mt-4">
                    {/* Starting from Field */}
                    <Col sm={12} lg={6}>
                      <div className="input-group">
                        <label htmlFor="start">
                          Starting from <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          id="start"
                          placeholder="Starting from"
                          value={formatForInput(
                            skillPayloadData.projectBidTimings.start
                          )}
                          onChange={handleBidTimingChange}
                          required
                        />
                      </div>
                    </Col>
                    {/* Ending Field */}
                    <Col sm={12} lg={6}>
                      <div className="input-group">
                        <label htmlFor="end">
                          Ending <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          id="end"
                          placeholder="Ending"
                          value={formatForInput(
                            skillPayloadData.projectBidTimings.end
                          )}
                          onChange={handleBidTimingChange}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <div>
              <div>
                <h5>Select Service Type</h5>
              </div>
              {/* <Card>
                <p>
                  Lorem ipsum is a dummy or placeholder text commonly used in
                  graphic design, publishing, and web development to fill empty
                  spaces in a layout that do not yet have content.
                </p>
                <div className="tagList">
                  <ul className="justify-content-start ps-0">
                    <li>Independent</li>
                    <li>Agency</li>
                    <li>Enterprise</li>
                  </ul>
                </div>
              </Card> */}

              <Card>
                <p>
                  Lorem ipsum is a dummy or placeholder text commonly used in
                  graphic design, publishing, and web development to fill empty
                  spaces in a layout that do not yet have content.
                </p>
                <div className="service_tagList">
                  <ul className="justify-content-start ps-0">
                    {["Independent", "Agency", "Enterprise"].map(
                      (serviceType) => (
                        <li
                          key={serviceType}
                          onClick={() =>
                            handleMultiSelect("serviceType", serviceType)
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight: skillPayloadData.serviceType.includes(
                              serviceType
                            )
                              ? ""
                              : "normal",
                            textDecoration:
                              skillPayloadData.serviceType.includes(serviceType)
                                ? ""
                                : "none",
                            background: skillPayloadData.serviceType.includes(
                              serviceType
                            )
                              ? "#F0806C"
                              : "#fff",
                            color: skillPayloadData.serviceType.includes(
                              serviceType
                            )
                              ? "#fff"
                              : "#000",
                          }}
                        >
                          {serviceType}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </Card>
            </div>
          </div>

          <div className="text-end mt-4">
            <button
              type="button"
              className="secondary-btn me-3"
              onClick={() => handleBack(false)}
            >
              Back
            </button>
            <button
              type="button"
              className="primary-btn"
              onClick={() => handleProSkillNext(true)}
            >
              Save and Continue
            </button>
          </div>
        </>
      ) : (
        <ProjectPreview
          payloadData={payloadData}
          skillPayloadData={skillPayloadData}
          handleNext={handleProSkillNext}
          videoSrc={videoSrc}
          userDetails={userDetails}
          projectId={projectId}
          userId={userId}
          certificateFile={certificateFile}
        />
      )}
    </>
  );
};

export default ProjectSkills;
