import React, { createContext, useContext, useState } from "react";

// Create Context
const ViewContext = createContext();

// Create a custom hook for easier usage
export const useViewContext = () => useContext(ViewContext);

// Provide Context to the component tree
export const ViewProvider = ({ children }) => {
  const [view, setView] = useState("default");
  const [projectID, setProjectID] = useState("");
  const [serviceData, setServiceData] = useState([]);


  const handleViewProposal = (id) => {
       
    setView("project-preview");
    setProjectID(id)
  };

  const resetView = () => {
    setView("default");
  };

  const handleShowMyBids = ()=>{
    setView("ShowMyBids");

  }

  const handleShowPublishedService = (data)=>{
    setView("ShowPublishedService");
    setServiceData(data)
  }

  const handleShowDrafts = (data) => {
    setView("ShowDrafts");
    setServiceData(data)
  };

  return (
    <ViewContext.Provider value={{ view,projectID, setView, handleViewProposal, resetView ,handleShowMyBids,serviceData,handleShowPublishedService,handleShowDrafts}}>
      {children}
    </ViewContext.Provider>
  );
};
