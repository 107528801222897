// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myproposals_sec select{
    border-radius: 30px;
    width: 150px;
}
.myproposals_sec p{
color: #989898;
display: -webkit-box;      
  -webkit-box-orient: vertical; 
  overflow: hidden;           
  text-overflow: ellipsis;    
  -webkit-line-clamp: 2;     
  line-height: 1.5;         
  max-height: calc(1.5em * 2);
  height:50px;
}
.myproposals_sec .card{
    background: #F6F6F6;
    border: 1px solid #EBEBEB;
    border-radius: 30px;
    padding:20px;
}
.proposals_card .profile_pic img{
    width: 40px;
    height:40px;
    border-radius: 50%;
}
.proposals_card label{
    color:  #626262;
}
.proposals_card .status-accepted {
    color: #00AB26;
    font-weight: 600;
}
.status-inprogress{
    color: #F7AD00;
    font-weight: 600;
}
.status-rejected{
    color: #ff0000;
    font-weight: 600;
}
.proposals_card h6 {
    color:  #F0806C;
    font-weight:500;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Pages/FindWorks/Tabs/MyProposals.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;AACA,cAAc;AACd,oBAAoB;EAClB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB;EACvB,qBAAqB;EACrB,gBAAgB;EAChB,2BAA2B;EAC3B,WAAW;AACb;AACA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".myproposals_sec select{\r\n    border-radius: 30px;\r\n    width: 150px;\r\n}\r\n.myproposals_sec p{\r\ncolor: #989898;\r\ndisplay: -webkit-box;      \r\n  -webkit-box-orient: vertical; \r\n  overflow: hidden;           \r\n  text-overflow: ellipsis;    \r\n  -webkit-line-clamp: 2;     \r\n  line-height: 1.5;         \r\n  max-height: calc(1.5em * 2);\r\n  height:50px;\r\n}\r\n.myproposals_sec .card{\r\n    background: #F6F6F6;\r\n    border: 1px solid #EBEBEB;\r\n    border-radius: 30px;\r\n    padding:20px;\r\n}\r\n.proposals_card .profile_pic img{\r\n    width: 40px;\r\n    height:40px;\r\n    border-radius: 50%;\r\n}\r\n.proposals_card label{\r\n    color:  #626262;\r\n}\r\n.proposals_card .status-accepted {\r\n    color: #00AB26;\r\n    font-weight: 600;\r\n}\r\n.status-inprogress{\r\n    color: #F7AD00;\r\n    font-weight: 600;\r\n}\r\n.status-rejected{\r\n    color: #ff0000;\r\n    font-weight: 600;\r\n}\r\n.proposals_card h6 {\r\n    color:  #F0806C;\r\n    font-weight:500;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
