// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my_messages .card{
    background: #fff;
    border: 1px solid #EBEBEB;
    border-radius: 30px;
    padding:20px;
}
.msg-card .card_bottom_sec label{
    color:  #F0806C !important;
    font-weight: 500 !important;
}
.name_sec label{
    color:#626262;
}
.name_sec h5{
    font-size: 18px;
}
.my_messages .card p{
    color: #787486;
}
my_messages p{
    color: #787486 !important;
}
.rating_label{
    font-size: 14px;
    color: #EB9C23 !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
}
.rating_label span{
    color: #989898;
    font-size: 12px;
}
.star {
    font-size: 24px !important;
    color: #EB9C23 !important;
}
.draft_messages .card{
    background: #F9F9F9 !important;
    border: 1px solid #EBEBEB;
    border-radius: 30px;
    padding:20px;
}
.card p{
    color: #989898;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/FindWorks/Tabs/Messages.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;AACA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":[".my_messages .card{\r\n    background: #fff;\r\n    border: 1px solid #EBEBEB;\r\n    border-radius: 30px;\r\n    padding:20px;\r\n}\r\n.msg-card .card_bottom_sec label{\r\n    color:  #F0806C !important;\r\n    font-weight: 500 !important;\r\n}\r\n.name_sec label{\r\n    color:#626262;\r\n}\r\n.name_sec h5{\r\n    font-size: 18px;\r\n}\r\n.my_messages .card p{\r\n    color: #787486;\r\n}\r\nmy_messages p{\r\n    color: #787486 !important;\r\n}\r\n.rating_label{\r\n    font-size: 14px;\r\n    color: #EB9C23 !important;\r\n    font-weight: 500 !important;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.rating_label span{\r\n    color: #989898;\r\n    font-size: 12px;\r\n}\r\n.star {\r\n    font-size: 24px !important;\r\n    color: #EB9C23 !important;\r\n}\r\n.draft_messages .card{\r\n    background: #F9F9F9 !important;\r\n    border: 1px solid #EBEBEB;\r\n    border-radius: 30px;\r\n    padding:20px;\r\n}\r\n.card p{\r\n    color: #989898;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
