// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-tabs .tabs button{
    border:none; 
    outline:none;
    background-color: transparent;
    margin-right:30px;
    padding-bottom: 10px;
    padding: 10px 5px;
}
.main-tabs .tabs {
    border-bottom: 1px solid #EEEAEA;
}
.main-tabs .tabs button.active{
    color:#F0806C;
    border-bottom:2px solid #F0806C;
    font-weight:600;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/FindWorks/Tabs/FreelancerView.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;AACrB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":[".main-tabs .tabs button{\r\n    border:none; \r\n    outline:none;\r\n    background-color: transparent;\r\n    margin-right:30px;\r\n    padding-bottom: 10px;\r\n    padding: 10px 5px;\r\n}\r\n.main-tabs .tabs {\r\n    border-bottom: 1px solid #EEEAEA;\r\n}\r\n.main-tabs .tabs button.active{\r\n    color:#F0806C;\r\n    border-bottom:2px solid #F0806C;\r\n    font-weight:600;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
