// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-button-group button{
    border: 1px solid #EEEAEA;
    outline:none;
    color: #363848;
    border-radius: 30px;
    background-color: #fff;
    padding: 7px 15px;
    margin-right: 10px;
}
.msg-button-group button.active{
    background: #F0806C;
    color:#fff;
    border:1px solid #F0806C;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/FindWorks/Tabs/MyService.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":[".msg-button-group button{\r\n    border: 1px solid #EEEAEA;\r\n    outline:none;\r\n    color: #363848;\r\n    border-radius: 30px;\r\n    background-color: #fff;\r\n    padding: 7px 15px;\r\n    margin-right: 10px;\r\n}\r\n.msg-button-group button.active{\r\n    background: #F0806C;\r\n    color:#fff;\r\n    border:1px solid #F0806C;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
