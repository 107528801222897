import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import flag from "../../../../Images/workspace_icons/save-add.png";
import add from "../../../../Images/flag.png";
import profile from "../../../../Images/profile.png";
import "./ShowMyBids.css";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import useToast from "../../../../Hooks/useToast";
import ApiCall from "../../../../Api/ApiCall";
import { useNavigate } from "react-router-dom";

const ShowMyBids = () => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `bid/user?userId=${userId}&role=client`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleBid = async (bidId, stage) => {
    setLoading(true);
    try {
      const payload = {
        bidId: bidId,
        stage: stage,
      };
      const response = await ApiCall("PUT", "bid", payload, "application/json");
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        console.log("successful:", response.data);
        notify("success", "You have successfully updated the bid!");
        navigate("/find-works");
      } else {
        notify(
          "error",
          response.message || "publish failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {activeRoleDetails && activeRoleDetails.length > 0 ? (
        activeRoleDetails.map((item, index) => (
          <div className="mybids-sec">
            <div>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>My Proposal</Breadcrumb.Item>
                <Breadcrumb.Item active>My Bids</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <h5>{item.title}</h5>
              </div>
              <div className="d-flex">
                <button type="button" className="secondary-btn">
                  Compare
                </button>
                <Form.Select aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">Accepted</option>
                  <option value="2">InProgress</option>
                  <option value="3">Rejected</option>
                </Form.Select>
              </div>
            </div>
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur. Ultrices curabitur
                <br />
                lobortis pharetra id nisi. Eu justo justo econsequat nisl..
              </p>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div class="grid-container" key={index}>
                    <div class="d-flex align-items-center grid-item">
                      <div className="profile_pic">
                        {/* <img src={item?.profilePhoto} alt="image" /> */}
                        <img src={profile} alt="image" />

                      </div>
                      <div className="ms-2">
                        <h5>{item?.firstName}</h5>
                        {/* <h5>{item?.title}</h5> */}
                        <p className="mb-0">{item?.description}</p>
                      </div>
                    </div>
                    <div class="grid-item">
                      <label>Submitted Date</label>
                      <h6>{item?.deliveryDate}</h6>
                    </div>
                    <div class="grid-item">
                      <label>Bid Amount</label>
                      <h6>${item?.bidAmount}/hour</h6>
                    </div>
                    <div class="grid-item item2 text-end">
                      <img src={flag} alt="flag" />
                      <img src={add} alt="add" className="ms-3" />
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex">
                      <div className="ms-3">
                        <label>Reputation Scrore</label>
                        <h6>4.5/5</h6>
                      </div>
                      <div className="ms-4">
                        <label>AI Verification</label>
                        <h6>Verified</h6>
                      </div>
                      <div className="ms-4">
                        <label>Client Rating</label>
                        <h6>4.5 (2547)</h6>
                      </div>
                    </div>
                    <div>
                      <a>View Proposal</a>
                      <button
                        type="button"
                        className="secondary-btn ms-3"
                        onClick={() => handleBid(item?.bidId, "rejected")}
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        className="primary-btn ms-3"
                        onClick={() => handleBid(item?.bidId, "accepted")}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))
      ) : (
        <div>No Bids available</div>
      )}
    </>
  );
};

export default ShowMyBids;
