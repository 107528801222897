import React, { useState } from "react";
import FreelancerView from "./FreelancerView";
import ClientView from "./ClientView";
import { useViewContext } from "../../../Context/ViewContext";
import ViewProposal from "./ViewProposal";
import ShowMyBids from "./Tabs/ShowMyBids";
import Publish from "../Service/Publish";
import ProjectView from "../MyWorks/ProjectView";
import Service from "../Service/Service";
import Project from "../Project/Project";

const FindWorksContent = () => {
  const [role, setRole] = useState("freelancer");
  const [activeRole, setActiveRole] = useState("freelancer");
  const { view, projectID, serviceData } = useViewContext();

  const handleRoleChange = (selectedRole) => {
    setRole(selectedRole);
    setActiveRole(selectedRole);
  };
  // console.log(view, "view");

  return (
    <>
      {view === "ShowPublishedService" &&
        (role === "freelancer" ? (
          <Publish serviceData={serviceData} type={"published"} />
        ) : (
          <ProjectView payloadData={serviceData} type={"published"} />
        ))}

      {view === "ShowDrafts" &&
        (role === "freelancer" ? (
          <Service draftData={serviceData} type={"draft"} />
        ) : (
          <Project projectData={serviceData} type={"draft"} />
        ))}

      {view === "ShowMyBids" && <ShowMyBids />}
      {view === "default" && (
        <div className="btns-wrap">
          <button
            onClick={() => handleRoleChange("freelancer")}
            className={activeRole === "freelancer" ? "active" : ""}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: activeRole === "freelancer" ? "#F0806C" : "#fff",
              color: activeRole === "freelancer" ? "white" : "black",
              border: "1px solid #ccc",
              borderRadius: "5px 0px 0px 5px",
              cursor: "pointer",
            }}
          >
            Freelancer
          </button>
          <button
            onClick={() => handleRoleChange("client")}
            className={activeRole === "client" ? "active" : ""}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: activeRole === "client" ? "#F0806C" : "#fff",
              color: activeRole === "client" ? "white" : "black",
              border: "1px solid #F0806C",
              borderRadius: "0px 5px 5px 0px",
              cursor: "pointer",
            }}
          >
            Client
          </button>
        </div>
      )}
      <div>
        {view === "default" &&
          (role === "freelancer" ? <FreelancerView /> : <ClientView />)}
        {view === "project-preview" && projectID && <ViewProposal />}
      </div>
    </>
  );
};

export default FindWorksContent;
