// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Event Styling */
.event-card {
    background-color: #e6f7ff; /* Light blue background */
    border: 1px solid #91d5ff;
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
    color: #004085;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .rbc-event {
    box-shadow: none !important;
  }
  
  /* Week View Customizations */
  .rbc-timeslot-group {
    min-height: 50px; /* Adjust time slot height */
  }
  
  .rbc-time-view {
    border-radius: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Common/customCalendar.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,yBAAyB,EAAE,0BAA0B;IACrD,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,2BAA2B;EAC7B;;EAEA,6BAA6B;EAC7B;IACE,gBAAgB,EAAE,4BAA4B;EAChD;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* Custom Event Styling */\r\n.event-card {\r\n    background-color: #e6f7ff; /* Light blue background */\r\n    border: 1px solid #91d5ff;\r\n    padding: 4px;\r\n    border-radius: 5px;\r\n    font-size: 12px;\r\n    color: #004085;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 5px;\r\n  }\r\n  \r\n  .rbc-event {\r\n    box-shadow: none !important;\r\n  }\r\n  \r\n  /* Week View Customizations */\r\n  .rbc-timeslot-group {\r\n    min-height: 50px; /* Adjust time slot height */\r\n  }\r\n  \r\n  .rbc-time-view {\r\n    border-radius: 8px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
