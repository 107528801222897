import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./customCalendar.css"; // Custom styling file

// Localizer for React Big Calendar
const localizer = momentLocalizer(moment);

// Sample Events Data
const events = [
  {
    id: 1,
    title: "☕ Monday Wake-Up Hour",
    start: new Date(2024, 12, 22, 8, 0), // 8:00 AM
    end: new Date(2024, 12, 22, 9, 0),   // 9:00 AM
  },
  {
    id: 2,
    title: "📝 All-Team Kickoff",
    start: new Date(2024, 11, 22, 9, 0),
    end: new Date(2024, 11, 22, 10, 0),
  },
  {
    id: 3,
    title: "📊 Financial Update",
    start: new Date(2024, 11, 22, 10, 0),
    end: new Date(2024, 11, 22, 11, 0),
  },
  {
    id: 4,
    title: "🎉 New Employee Welcome Lunch!",
    start: new Date(2024, 11, 22, 11, 0),
    end: new Date(2024, 11, 22, 12, 0),
  },
  {
    id: 5,
    title: "🛠 Design System Kickoff Lunch",
    start: new Date(2024, 11, 22, 12, 0),
    end: new Date(2024, 11, 22, 1, 0),
  },
];

// Custom Event Component
const CustomEvent = ({ event }) => (
  <div className="event-card">
    <strong>{event.title}</strong>
  </div>
);

// Calendar Component
const MyCustomCalendar = () => {
  
  return (
    <div style={{ height: "80vh", padding: "20px" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["day","week", "month" ]}
        defaultView="week"
        step={60}
        timeslots={1}
        min={new Date(2024, 6, 22, 7, 0)} // Start at 7 AM
        max={new Date(2024, 6, 22, 19, 0)} // End at 7 PM
        components={{
          event: CustomEvent, // Use custom event rendering
        }}
        style={{ height: "100%" }}
      />
    </div>
  );
};

export default MyCustomCalendar;
