import React from "react";

const ShowModal = ({handleProfile}) => {
  return (
    <>
      <div>Enhance your Profile</div>
      <div>
        A mini game competition for the top Freela profile. Level up and secure
        more projects!
      </div>

      <div>
        <button
          type="button"
          className="primary-btn w-100 mt-4 py-3"
          onClick={handleProfile}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default ShowModal;
