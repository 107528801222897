import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./MyProposals.css";
import { Row, Col, Card } from "react-bootstrap";
import profile from "../../../../Images/profile.png";
import dollar from "../../../../Images/dollar.svg";
import MyBids from "./MyBids";
import SavedBids from "./SavedBids";
import useToast from "../../../../Hooks/useToast";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import Loader from "../../../Common/Loader/Loader";
import { formatDateToDDMMYYYYC } from "../../../Common/utils/utils";

const MyProposals = ({ role }) => {
  const [activeButton, setActiveButton] = useState("myBids"); // Default to "My Bids"

  const [loading, setLoading] = useState(false);
  const [proposalDetails, setProposalDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");

  const debouncedGetProposalDetails = debounce(() => {
    getProposalDetails();
  }, 300);

  useEffect(() => {
    if (role === "freelancer") {
      debouncedGetProposalDetails();
    }
    return () => debouncedGetProposalDetails.cancel();
  }, [role]);

  const getProposalDetails = async () => {
    setLoading(true);
    setProposalDetails([]);
    try {
      const endpoint = role === "freelancer" ? `bid/user?userId=${userId}&role=${role} ` : ``;  
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setProposalDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setProposalDetails([]);
    try {
      const endpoint = `bid/filterByStatus?freelancerId=${userId}&stage=${stage}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setProposalDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  // console.log(proposalDetails, "proposalDetails");

  const renderClientContent = () => {
    switch (activeButton) {
      case "myBids":
        return (
          <div>
            <MyBids />
          </div>
        );
      case "published":
        return (
          <div>
            <SavedBids />
          </div>
        );
      default:
        return <div>No content available</div>;
    }
  };

  const renderFreelancerContent = () => {
    return (
      <div className="myproposals_sec">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3>My Proposals</h3>
          </div>
          <div>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                const stage = e.target.value;
                if (stage !== "Select") {
                  getProposalDetailsByStage(stage);
                }
                else{
                  getProposalDetails()
                }
              }}
            >
              <option>Select</option>
              <option value="accepted">Accepted</option>
              <option value="inProgress">Inprogress</option>
              <option value="rejected">Rejected</option>
            </Form.Select>
          </div>
        </div>
        <p>
          Lorem ipsum is a dummy or placeholder text commonly used in graphic
          design
        </p>
        <div></div>
        <Row>
          {proposalDetails && proposalDetails.length > 0 ? (
            proposalDetails.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                className="mt-3"
                key={index}
              >
                <Card className="proposals_card">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex w-100">
                      <div className="profile_pic me-3">
                        <img src={item.profilePhoto} alt="profilephoto" />
                      </div>
                      <div>
                        <h6 className="mb-0">{item.firstName}</h6>
                        <label>United States</label>
                      </div>
                    </div>
                    <div className="text-end">
                      <label>
                        Bid Date{" "}
                        <span>{formatDateToDDMMYYYYC(item.deliveryDate)}</span>
                      </label>
                      <label>
                        Status{" "}
                        <span className="status-accepted">{item.stage}</span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5>{item.title}</h5>
                    <p>{item.message}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <img src={dollar} alt="dollar" />
                      </div>
                      <div className="ms-3">
                        <label>
                          Paid in
                          <br />
                          US Dollar
                        </label>
                      </div>
                    </div>
                    <div className="text-end me-3">
                      <label>Bid amount</label>
                      <h6>$ {item.bidAmount}/hour</h6>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div>No Proposals available</div>
          )}
        </Row>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}

      <div className="my-proposals">
        {role === "client" ? (
          <div>
            {/* Buttons for Client */}
            <div className="button-group msg-button-group">
              <button
                onClick={() => setActiveButton("myBids")}
                className={activeButton === "myBids" ? "active" : ""}
              >
                My Bids
              </button>
              <button
                onClick={() => setActiveButton("published")}
                className={activeButton === "published" ? "active" : ""}
              >
                Saved Bids
              </button>
            </div>

            {/* Dynamic Content for Client */}
            <div className="content">{renderClientContent()}</div>
          </div>
        ) : (
          renderFreelancerContent()
        )}
      </div>
    </>
  );
};

export default MyProposals;
