import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, CardHeader } from "react-bootstrap";
import add from "../../../../Images/add-square.png";
import clock from "../../../../Images/icons/clock.png";
import wallet from "../../../../Images/wallet.png";
import "./MyTasks.css";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import { calculateRemainingDays } from "../../../Common/utils/utils";

const MyTasks = ({ role }) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const [inprogressDetails, setInprogressDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const debouncedGetActiveRoleDetails = debounce(() => {
    getProposalDetailsByStage();
    getInprogressDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  // console.log(role, "role in MyTasks");

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `bid/filterByStatus?freelancerId=${userId}&stage=accepted`
          : `bid/filterByStatus?clientId=${userId}&stage=accepted`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getInprogressDetails = async () => {
    setLoading(true);
    setInprogressDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `project/filterByStatus?assigned_userId=${userId}&projectStatus=InProgress`
          : `project/filterByStatus?userId=${userId}&projectStatus=InProgress`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setInprogressDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBid = async (projectId) => {
    setLoading(true);

    try {
      const payload = {
        projectId: projectId,
        projectStatus: "InProgress",
      };
      const response = await ApiCall(
        "POST",
        "project/updateProjectActivity",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 200) {
        // console.log("successful:", response.data);
        notify("success", "You have started the project");
        getProposalDetailsByStage();
        getInprogressDetails();
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {role === "client" ? (
        <div className="my_tasks_sec">
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="todo_card">
                <Card.Header className="d-flex align-items-center justify-content-between pb-2">
                  <div className="d-flex align-items-center">
                    <div className="todo_circle"></div>
                    <h6 className="mb-0 ms-2">
                      TO Do{" "}
                      <span className="count">{activeRoleDetails.length}</span>
                    </h6>
                  </div>
                  <div>
                    <img src={add} alt="add" />
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  {activeRoleDetails && activeRoleDetails.length > 0 ? (
                    activeRoleDetails.map((item, index) => (
                      <Card className="inner-card" key={index}>
                        <div className="d-flex justify-content-between">
                          <h5>{item.title}</h5>
                          <label>
                            <img src={wallet} alt="wallet" className="me-1" />${" "}
                            {item.bidAmount}
                          </label>
                        </div>
                        <p className="mt-2">{item.message}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="mb-0">Time to Complete</h6>
                          <div>
                            <img
                              src={clock}
                              alt="clock"
                              className="clock_img me-1"
                            />
                            {calculateRemainingDays(item.projectDueDate)} Days Delivery
                          </div>
                        </div>
                        <div
                          className="text-end my-3"
                          onClick={() => handleUpdateBid(item.projectId)}
                        >
                          <label>Start Activity</label>
                        </div>
                      </Card>
                    ))
                  ) : (
                    <div>No Todos available</div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="inprogress_card">
                <Card.Header className="pb-3">
                  <div className="d-flex align-items-center">
                    <div className="inprogress_circle"></div>
                    <h6 className="mb-0 ms-2">
                      In Progress <span className="count">{inprogressDetails.length}</span>
                    </h6>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                {inprogressDetails && inprogressDetails.length > 0 ? (
                    inprogressDetails.map((item, index) => (
                  <Card className="inner-card" key={index}>
                    <div className="d-flex justify-content-between">
                      <h5>{item.title}</h5>
                      <label>
                        <img src={wallet} alt="wallet" className="me-1" />$ {item.amountforFreelancer}
                      </label>
                    </div>
                    <p className="mt-2">
                     {item.description}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0">Deadline</h6>
                      <div>
                        <img
                          src={clock}
                          alt="clock"
                          className="clock_img me-1"
                        />
                        {calculateRemainingDays(item.projectDueDate)} Days Delivery
                      </div>
                    </div>
                    <div className="text-end my-3">
                      <label>View Workspace</label>
                    </div>
                  </Card>
                   ))
                  ) : (
                    <div>No In Progress available</div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="done_card">
                <Card.Header className="pb-3">
                  <div className="d-flex align-items-center">
                    <div className="done_circle"></div>
                    <h6 className="mb-0 ms-2">
                      Done <span className="count">2</span>
                    </h6>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <Card className="inner-card">
                    <div className="d-flex justify-content-between">
                      <h5>Mobile App Design</h5>
                      <label>
                        <img src={wallet} alt="wallet" className="me-1" />$ 150
                      </label>
                    </div>
                    <p className="mt-2">
                      Lorem ipsum is a dummy or placeholder text commonly used
                      in graphic design
                    </p>

                    <div className="mb-3 green_text">
                      <label>Completed</label>
                    </div>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="my_tasks_sec">
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="todo_card">
                <Card.Header className="d-flex align-items-center justify-content-between pb-2">
                  <div className="d-flex align-items-center">
                    <div className="todo_circle"></div>
                    <h6 className="mb-0 ms-2">
                      TO Do{" "}
                      <span className="count">{activeRoleDetails.length}</span>
                    </h6>
                  </div>
                  <div>
                    <img src={add} alt="add" />
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  {activeRoleDetails && activeRoleDetails.length > 0 ? (
                    activeRoleDetails.map((item, index) => (
                      <Card className="inner-card" key={index}>
                        <div className="d-flex justify-content-between">
                          <h5>{item.title}</h5>
                          <label>
                            <img src={wallet} alt="wallet" className="me-1" />${" "}
                            {item.bidAmount}
                          </label>
                        </div>
                        <p className="mt-2">{item.message}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="mb-0">Time to Complete</h6>
                          <div>
                            <img
                              src={clock}
                              alt="clock"
                              className="clock_img me-1"
                            />
                            {calculateRemainingDays(item.projectDueDate)} Days Delivery
                          </div>
                        </div>
                        <div
                          className="text-end my-3"
                          onClick={() => handleUpdateBid(item.projectId)}
                        >
                          <label>Start Activity</label>
                        </div>
                      </Card>
                    ))
                  ) : (
                    <div>No Todos available</div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="inprogress_card">
                <Card.Header className="pb-3">
                  <div className="d-flex align-items-center">
                    <div className="inprogress_circle"></div>
                    <h6 className="mb-0 ms-2">
                      In Progress <span className="count">{inprogressDetails.length}</span>
                    </h6>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                {inprogressDetails && inprogressDetails.length > 0 ? (
                    inprogressDetails.map((item, index) => (
                  <Card className="inner-card" key={index}>
                    <div className="d-flex justify-content-between">
                      <h5>{item.title}</h5>
                      <label>
                        <img src={wallet} alt="wallet" className="me-1" />$ {item.amountforFreelancer}
                      </label>
                    </div>
                    <p className="mt-2">
                     {item.description}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0">Deadline</h6>
                      <div>
                        <img
                          src={clock}
                          alt="clock"
                          className="clock_img me-1"
                        />
                        {calculateRemainingDays(item.projectDueDate)} Days Delivery
                      </div>
                    </div>
                    <div className="text-end my-3">
                      <label>View Workspace</label>
                    </div>
                  </Card>
                   ))
                  ) : (
                    <div>No In Progress available</div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
              <Card className="done_card">
                <Card.Header className="pb-3">
                  <div className="d-flex align-items-center">
                    <div className="done_circle"></div>
                    <h6 className="mb-0 ms-2">
                      Done <span className="count">2</span>
                    </h6>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <Card className="inner-card">
                    <div className="d-flex justify-content-between">
                      <h5>Mobile App Design</h5>
                      <label>
                        <img src={wallet} alt="wallet" className="me-1" />$ 150
                      </label>
                    </div>
                    <p className="mt-2">
                      Lorem ipsum is a dummy or placeholder text commonly used
                      in graphic design
                    </p>

                    <div className="mb-3 green_text">
                      <label>Completed</label>
                    </div>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default MyTasks;
