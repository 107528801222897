import React, { useEffect, useRef, useState } from "react";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import ApiCall from "../../../Api/ApiCall";
import { useViewContext } from "../../../Context/ViewContext";
import { Card, Row, Col } from "react-bootstrap";
import { debounce } from "lodash";
import playBtn from "../../../Images/play_btn.png";
import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
import profile from "../../../Images/profile_pic.png";
import online from "../../../Images/online_icon.png";
import Modal from "../../Common/Modal/CustomModal";
import PlaceBid from "../MyWorks/PlaceBid";
import { calculateRemainingDays } from "../../Common/utils/utils";
import pdfIcon from "../../../Images/icons/pdf-icon.svg";

const ViewProposal = () => {
  const [loading, setLoading] = useState(false);
  const [proposalDetails, setProposalDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const [showBidModal, setShowBidModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);
  const handlePlayPause = () => {
    console.log("handlePlayPause");

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const { projectID } = useViewContext();
  console.log(projectID, "projectID ViewProposaqqqqqqqqqqqqqqql");

  const debouncedGetViewDetails = debounce(() => {
    getViewProposalDetails();
  }, 300);

  useEffect(() => {
    if (projectID) {
      debouncedGetViewDetails();
    }
    return () => debouncedGetViewDetails.cancel();
  }, [projectID]);

  const getViewProposalDetails = async () => {
    console.log("a");

    setLoading(true);
    setProposalDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `project/${projectID}`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setProposalDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  // console.log(proposalDetails, "proposalDetails");
  const handleBid = () => {
    setShowBidModal(true);
  };
  const handleCloseModal = () => {
    setShowBidModal(false);
  };

  const daysLeft = calculateRemainingDays(
    proposalDetails?.projectBidTimings?.end
  );
  console.log(daysLeft, "daysLeft");

  return (
    <>
      {loading && <Loader />}
      {showBidModal && (
        <Modal isOpen={showBidModal} onClose={handleCloseModal}>
          <PlaceBid
            projectId={proposalDetails?.projectId}
            clientId={proposalDetails?.userId}
            freelancerId={userId}
            freelancerName={proposalDetails?.firstName}
            userId={userId}
            type={"bid"}
            daysLeft={daysLeft}
          />
        </Modal>
      )}
      {proposalDetails.length !== 0 && (
        <Row className="mt-4">
          <Col sm={12} md={8} lg={8} className="project_preview_left">
            <Card className="about_card">
              <div className="about_heading_sec">
                <h5>{proposalDetails?.title}</h5>
              </div>
              <div className="profile_sec mt-3">
                <div className="profile_img">
                  <img src={proposalDetails?.profilePhoto} alt="profile" />
                  <div className="profile_img_online">
                    <img src={online} alt="profile" />
                  </div>
                </div>
                <div className="ms-2">
                  <h6 className="mb-0">{proposalDetails?.firstName}</h6>
                  <label>Freelancer - UI UX Designer</label>
                </div>
              </div>
              <div className="mt-4">
                <h5>About Project</h5>
                <p>{proposalDetails?.description}</p>
              </div>
              <div className="mt-4">
                <h5>Instruction</h5>
                <p> {proposalDetails?.instruction}</p>
                <div>
                  <h5>Tags</h5>
                  <div className="tagList">
                    <ul className="justify-content-start ps-0">
                      {proposalDetails?.skillsNeeded?.map((tag, index) => (
                        <li key={index}>{tag}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <h5>Document</h5>
                  </div>
                  <div className="file-preview ms-3">
                    <div className="file-details">
                      <img src={pdfIcon} alt="PDF Icon" className="file-icon" />
                      <div className="file-info ms-3">
                        <p className="file-title mb-0">
                          {proposalDetails?.media[0]?.document?.name}
                        </p>
                        <p className="file-meta mb-0">
                          {proposalDetails?.media[0]?.document?.size} KB ·{" "}
                          {new Date(
                            proposalDetails?.media[0]?.document?.lastModified
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="mt-4">
              <Row>
                <Col sm={12}>
                  <Card className="image-caurosel p-0">
                    <img
                      src={proposalDetails?.media[0]?.coverImage}
                      alt=""
                      className="w-100"
                    />
                  </Card>
                </Col>
                <Row className="pe-0">
                  <Col sm={12} className="pe-0">
                    <Card className="mt-3">
                      <Row className="align-items-center">
                        <Col sm={12} md={8} lg={8}>
                          <h6>Video</h6>
                          <p className="mb-0">
                            {proposalDetails?.media[0]?.video?.description}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          className="coverphoto-preview"
                        >
                          {/* <img src={proposalDetails?.profilePhoto} alt="" className="w-100" /> */}
                          <div className="video-preview position-relative">
                            {/* Video Preview */}
                            <video
                              ref={videoRef}
                              // src={proposalDetails?.video}
                              src={proposalDetails?.media[0]?.video?.url}
                              className="w-100" // Adjust size as needed, full width of the container
                              onClick={handlePlayPause} // Toggle play/pause on click
                              onEnded={() => setIsPlaying(false)} // Reset play state when video ends
                              style={{ cursor: "pointer" }}
                              controls={false} // Hide default controls
                            />
                            {/* Play/Pause Button */}
                            <button
                              onClick={handlePlayPause}
                              className="play-button position-absolute"
                              style={{
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                background: "transparent",
                                border: "none",
                              }}
                            >
                              <div className="play_btn">
                                <img
                                  src={isPlaying ? "pauseIcon" : playBtn}
                                  alt={isPlaying ? "Pause" : "Play"}
                                />
                              </div>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row className="pe-0">
                  <Col sm={12} className="pe-0">
                    <Card className="mt-3">
                      <Row className="align-items-center">
                        <Col sm={12} md={8} lg={8}>
                          <h6>Photo</h6>
                          <p className="mb-0">
                            {proposalDetails?.media[0]?.photo?.description}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          className="coverphoto-preview"
                        >
                          <img
                            src={proposalDetails?.media[0]?.photo?.url}
                            alt=""
                            className="w-100"
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={4} lg={4} className="project_preview_right">
            <Card>
              <div className="d-flex justify-content-between py-3">
                <h5>Reward</h5>
                <h5>$ {proposalDetails?.estimateBudget?.max}</h5>
              </div>
              <div className="d-flex justify-content-between py-3">
                <h5>Paid in</h5>
                <h5>
                  {proposalDetails.acceptedPaymentModes === "FREL" ? (
                    <img src={frel} alt="frel" />
                  ) : proposalDetails.acceptedPaymentModes === "USD" ? (
                    <img src={usd} alt="dollar" />
                  ) : proposalDetails.acceptedPaymentModes === "INR" ? (
                    <img src={inr} alt="inr" />
                  ) : proposalDetails.acceptedPaymentModes === "EURO" ? (
                    <img src={euro} alt="euro" />
                  ) : proposalDetails.acceptedPaymentModes === "POUND" ? (
                    <img src={pound} alt="pound" />
                  ) : proposalDetails.acceptedPaymentModes === "BITCOIN" ? (
                    <img src={bitcoin} alt="bitcoin" />
                  ) : proposalDetails.acceptedPaymentModes === "LITECOIN" ? (
                    <img src={litecoin} alt="litecoin" />
                  ) : proposalDetails.acceptedPaymentModes === "ETHEREUM" ? (
                    <img src={ethereum} alt="ethereum" />
                  ) : (
                    <img src={usdt} alt="usdt" />
                  )}
                  {proposalDetails.acceptedPaymentModes}
                </h5>
              </div>
              <div className="d-flex justify-content-between py-3">
                <h5>Time to Complete</h5>
                <label>
                  {proposalDetails?.paymentType?.duration}{" "}
                  {proposalDetails?.paymentType?.type} Delivery
                </label>
              </div>
              <div className="mt-3">
                <h5>Required Skills</h5>
              </div>
              <div className="tagList mt-3">
                <ul className="justify-content-start ps-0">
                  {proposalDetails?.skillsNeeded?.map((tag, index) => (
                    <li key={index}>{tag}</li>
                  ))}
                </ul>
              </div>
            </Card>
            <div>
              <button
                disabled={daysLeft === 0}
                type="button"
                className="primary-btn w-100 mt-4 py-3"
                onClick={handleBid}
              >
                Bid The Project
              </button>
              <p> {daysLeft} days left to bid</p>
              {/* <button
                type="button"
                className="secondary-btn w-100 mt-3 py-3"
                onClick={() => handleNext(false)}
              >
                Back
              </button> */}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ViewProposal;
