import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import listview from "../../../Images/list_view.png";
import gridview from "../../../Images/grid_view.png";
import gridFilled from "../../../Images/grid-filled.png"
import online from "../../../Images/workspace_icons/online.png";
import profilePic from "../../../Images/profile.png";
import reputation from "../../../Images/reputation_score.png";
import aiverify from "../../../Images/ai_verification.png";
import clientrating from "../../../Images/client-rating.png";
import amount from "../../../Images/amount_usd.png";

import frel from "../../../Images/payment_icons/frel.png";
import usd from "../../../Images/payment_icons/usd.png";
import inr from "../../../Images/payment_icons/inr.png";
import euro from "../../../Images/payment_icons/euro.png";
import pound from "../../../Images/payment_icons/pound.png";
import bitcoin from "../../../Images/payment_icons/bitcoin.png";
import litecoin from "../../../Images/payment_icons/litecoin.png";
import ethereum from "../../../Images/payment_icons/ethereum.png";
import usdt from "../../../Images/payment_icons/usdt.png";
import flag from "../../../Images/flag.png";
import add from "../../../Images/workspace_icons/save-add.png";
import profile from "../../../Images/profile.png";
import reputationList from "../../../Images/workspace_icons/reputation_score.png";
import aiverifyList from "../../../Images/workspace_icons/ai_verification.png";
import clientratingList from "../../../Images/workspace_icons/client_rating.png";
import amountList from "../../../Images/workspace_icons/amount_usd.png";
import payments from "../../../Images/workspace_icons/payments_accepted.png";

import { Range } from "react-range";

import "./MyWorks.css";
import ApiCall from "../../../Api/ApiCall";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import { debounce } from "lodash";
import Publish from "../Service/Publish";
import loaction from "../../../Images/workspace_icons/map.svg";

import ProjectView from "./ProjectView";
import Modal from "../../Common/Modal/CustomModal";
import ShowModal from "./ShowModal";
import Profile from "../Profile/Profile/Profile";

const MyWorks = ({ userDetails }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeRole, setActiveRole] = useState("freelancer");
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const { notify } = useToast();

  const [skillPayloadData, setSkillPayloadData] = useState({
    skills: "",
    skillsNeeded: [],
    tier: [],
    serviceType: [],
    estimateBudget: {
      min: 0, // Minimum budget
      max: 0, // Maximum budgets
    },
    acceptedPaymentModes: "",
    paymentType: {
      type: "Hours",
      duration: 0,
    },
  });
  const [skillList, setSkillList] = useState([]);
  const [isGridView, setIsGridView] = useState(true);

  useEffect(() => {
    setSkillPayloadData((prev) => ({ ...prev, skillsNeeded: skillList }));
  }, [skillList]);

  const STEP = 10; // Increment steps
  const MIN = 0; // Minimum value
  const MAX = 500; // Maximum value

  const handleRangeChange = (values) => {
    // console.log(values,"values");

    setSkillPayloadData((prev) => ({
      ...prev,
      estimateBudget: {
        min: values[0],
        max: values[1],
      },
    }));
  };

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (activeRole) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [activeRole]);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint = activeRole === "freelancer" ? "project" : "service";
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const handleCard = (Id) => {
    if (userDetails?.isFirstTimeLogin) {
      console.log("go to profle");
      setShowPopup(true);
    } else {
      console.log("got to service/project ", Id);

      getCardDetails(Id);
    }
  };

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  const handleProfile = () => {
    console.log("show profile");
    handleCloseModal();
    setShowProfile(true);
  };
  const getCardDetails = async (Id) => {
    setLoading(true);
    setCardDetails([]);

    try {
      const endpoint =
        activeRole === "freelancer" ? `project/${Id}` : `service/${Id}`;
      console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setCardDetails(response.data);
        setShowCardDetails(true);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  // console.log(cardDetails, "cardDetails");

  return (
    <>
      {loading && <Loader />}
      {showPopup && (
        <Modal isOpen={showPopup} onClose={handleCloseModal}>
          <ShowModal handleProfile={handleProfile} />
        </Modal>
      )}

      {showProfile ? (
        <Profile />
      ) : showCardDetails ? (
        activeRole === "freelancer" ? (
          <ProjectView
            payloadData={cardDetails}
            userDetails={userDetails}
            type={"bid"}
          />
        ) : (
          <Publish
            serviceData={cardDetails}
            userDetails={userDetails}
            type={"bid"}
          />
        )
      ) : (
        <div>
          <div className="find-work-sec">
            <Row>
              <Col xs={12} sm={12} lg={12}>
                <Card>
                  <div>
                    <h1>Find your work</h1>
                  </div>
                  <div className="search_sec my-4">
                    <div className="w-25">
                      <Form.Select aria-label="Default select example">
                        <option>Location</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                    <div className="w-25">
                      <Form.Select aria-label="Default select example">
                        <option>Category</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                    <div className="w-50 d-flex">
                      <Form.Control type="text" placeholder="Search here..." />
                      <div>
                        <button type="button" className="primary-btn">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="worklist">
                    <ul>
                      <li>Web Dev</li>
                      <li>Mobile Dev</li>
                      <li>Design</li>
                      <li>Writing</li>
                      <li>Admin Support</li>
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col xs={12} sm={12} md={12} lg={7} xl={9}>
                <Card className="people_left_card">
                  <div className="d-flex align-items-center justify-content-between flex_wrap">
                    <div className="btns-wrap">
                      <button
                        onClick={() => setActiveRole("freelancer")}
                        className={activeRole === "freelancer" ? "active" : ""}
                        style={{
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor:
                            activeRole === "freelancer" ? "#F0806C" : "#fff",
                          color:
                            activeRole === "freelancer" ? "white" : "black",
                          border: "1px solid #ccc",
                          borderRadius: "5px 0px 0px 5px",
                          cursor: "pointer",
                        }}
                      >
                        Freelancer
                      </button>
                      <button
                        onClick={() => setActiveRole("client")}
                        className={activeRole === "client" ? "active" : ""}
                        style={{
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor:
                            activeRole === "client" ? "#F0806C" : "#fff",
                          color: activeRole === "client" ? "white" : "black",
                          border: "1px solid #F0806C",
                          borderRadius: "0px 5px 5px 0px",
                          cursor: "pointer",
                        }}
                      >
                        Client
                      </button>
                    </div>
                    <div className="d-flex align-items-center">
                    <div className="img_backround">
                    <img
                     src={gridview} alt="image" 
                       
                        className="img-width"
                        onClick={() => setIsGridView(true)}
                       
                      />
                      <img
                        src={listview}
                        alt="list"
                        className="ms-1 img-width"
                        onClick={() => setIsGridView(false)}
                      />
                    </div>
                     
                      <div className="ms-3">
                        <Form.Select aria-label="Default select example">
                          <option>Select</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="mt-4">
                      Showing {activeRoleDetails?.length}{" "}
                      {activeRole === "freelancer" ? "projects" : "services"}
                    </h3>
                  </div>
                  <Row>
                    {isGridView ? (
                      activeRoleDetails && activeRoleDetails.length > 0 ? (
                        activeRoleDetails.map((item, index) => (
                          <Col
                            sm={12}
                            md={12}
                            lg={12}
                            xl={6}
                            className="mt-4"
                            key={index}
                          >
                            <Card
                              className="people_card"
                              onClick={() =>
                                handleCard(item.serviceId || item.projectId)
                              }
                            >
                              <Card.Body className="p-0">
                                <div className="p-4">
                                  <div className="d-flex">
                                    <div className="d-flex">
                                      <div className="profile_img">
                                        <img
                                          src={item?.profilePhoto}
                                          alt="profile"
                                          className="prof_pic"
                                        />
                                        <div className="profile_img_online">
                                          <img
                                            src={online}
                                            alt="online status"
                                          />
                                        </div>
                                      </div>
                                      <div className="ms-3">
                                        <h4 className="mb-0">
                                          {item?.firstName}
                                        </h4>
                                        <label>
                                          Freelancer - UI UX Designer
                                        </label>
                                        <label>
                                          <img src={loaction} alt="image" />{" "}
                                          India - Posted by :{" "}
                                          {new Date(
                                            item.createdAt
                                          ).toLocaleString()}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="d-flex right_imgs">
                                      <img src={flag} alt="flag" />
                                      <img src={add} alt="add" />
                                    </div>
                                  </div>
                                  <p className="pt-3 mb-0">
                                    {item?.title || "No tilte available"}
                                  </p>

                                  <p className="pt-3 project_desc">
                                    {item?.description ||
                                      "No description available"}
                                  </p>
                                  {/* <div className="peoplelist">
                                <ul>
                                  <li>Visual Designer</li>
                                  <li>UI UX Designer</li>
                                  <li>Figma</li>
                                </ul>
                              </div> */}
                                  <div className="peoplelist">
                                    {activeRole != "freelancer" ? (
                                      <ul>
                                        {item.skills &&
                                        item.skills.length > 0 ? (
                                          item.skills.map(
                                            (skill, skillIndex) => (
                                              <li key={skillIndex}>{skill}</li>
                                            )
                                          )
                                        ) : (
                                          <li>No skills listed</li>
                                        )}
                                      </ul>
                                    ) : (
                                      <ul>
                                        {item.skillsNeeded &&
                                        item.skillsNeeded.length > 0 ? (
                                          item.skillsNeeded.map(
                                            (skill, skillIndex) => (
                                              <li key={skillIndex}>{skill}</li>
                                            )
                                          )
                                        ) : (
                                          <li>No skills listed</li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                                <div className="work-grid-container">
                                  <div className="grid-item">
                                    <img src={reputation} alt="reputation" />
                                    <div>
                                      <label>Reputation Score</label>
                                      <h6>
                                        <span>4.5</span>/5
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="grid-item">
                                    <img src={aiverify} alt="AI Verification" />
                                    <div>
                                      <label>AI Verification</label>
                                      <h6>
                                        <span>Verified</span>
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="grid-item">
                                    <img
                                      src={clientrating}
                                      alt="Client Rating"
                                    />
                                    <div>
                                      <label>Client Rating</label>
                                      <h6>
                                        <span>4.5</span>(2547)
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="grid-item">
                                    <img src={amount} alt="Amount" />
                                    <div>
                                      <label>
                                        {" "}
                                        {activeRole === "freelancer"
                                          ? "Project Budget"
                                          : "Amount USD"}
                                      </label>
                                      <h6>
                                        <span>
                                          {activeRole === "freelancer"
                                            ? `$${
                                                item?.estimateBudget?.min ??
                                                "N/A"
                                              } - $${
                                                item?.estimateBudget?.max ??
                                                "N/A"
                                              }`
                                            : `$${
                                                item?.tiers?.[0]?.price ?? "N/A"
                                              }/Hour`}
                                        </span>
                                        
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                              <Card.Footer className="border-0 p-2">
                                <div className="payment_iocns">
                                  <label>
                                    Payment Accepted
                                    {activeRole != "freelancer" ? (
                                      <>
                                        {item?.acceptedPaymentModes[0]
                                          ?.freelcoin && (
                                          <img
                                            src={frel}
                                            alt="freelcoin"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]
                                          ?.traditional?.usd && (
                                          <img
                                            src={usd}
                                            alt="usd"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]
                                          ?.traditional?.inr && (
                                          <img
                                            src={inr}
                                            alt="inr"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]
                                          ?.traditional?.euro && (
                                          <img
                                            src={euro}
                                            alt="euro"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]
                                          ?.traditional?.pound && (
                                          <img
                                            src={pound}
                                            alt="pound"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]?.other
                                          ?.bitcoin && (
                                          <img
                                            src={bitcoin}
                                            alt="bitcoin"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]?.other
                                          ?.litecoin && (
                                          <img
                                            src={litecoin}
                                            alt="litecoin"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]?.other
                                          ?.ethereum && (
                                          <img
                                            src={ethereum}
                                            alt="ethereum"
                                            className="me-3"
                                          />
                                        )}
                                        {item?.acceptedPaymentModes[0]?.other
                                          ?.usdt && (
                                          <img
                                            src={usdt}
                                            alt="usdt"
                                            className="me-3"
                                          />
                                        )}
                                      </>
                                    ) : item.acceptedPaymentModes === "FREL" ? (
                                      <img src={frel} alt="frel" />
                                    ) : item.acceptedPaymentModes === "USD" ? (
                                      <img src={usd} alt="dollar" />
                                    ) : item.acceptedPaymentModes === "INR" ? (
                                      <img src={inr} alt="inr" />
                                    ) : item.acceptedPaymentModes === "EURO" ? (
                                      <img src={euro} alt="euro" />
                                    ) : item.acceptedPaymentModes ===
                                      "POUND" ? (
                                      <img src={pound} alt="pound" />
                                    ) : item.acceptedPaymentModes ===
                                      "BITCOIN" ? (
                                      <img src={bitcoin} alt="bitcoin" />
                                    ) : item.acceptedPaymentModes ===
                                      "LITECOIN" ? (
                                      <img src={litecoin} alt="litecoin" />
                                    ) : item.acceptedPaymentModes ===
                                      "ETHEREUM" ? (
                                      <img src={ethereum} alt="ethereum" />
                                    ) : (
                                      <img src={usdt} alt="usdt" />
                                    )}
                                  </label>
                                </div>
                              </Card.Footer>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <div>
                          No 
                          {activeRole === "freelancer"
                            ? " projects "
                            : " services "}
                           available
                        </div>
                      )
                    ) : (
                      <>
                        <Row>
                          {activeRoleDetails && activeRoleDetails.length > 0 ? (
                            activeRoleDetails.map((item, index) => (
                              <Col sm={12} md={12} lg={12} key={index} className="mt-3">
                                <Card
                                  className="listview_card"
                                  onClick={() =>
                                    handleCard(item.serviceId || item.projectId)
                                  }
                                >
                                  <div className="d-flex">
                                    <div className="left_sec">
                                      <div className="profile_image">
                                        <img
                                          src={item?.profilePhoto}
                                          alt="image"
                                        />
                                      </div>
                                      <div className="mt-2">
                                        <h4> {item?.firstName}</h4>
                                        <label>
                                          Freelancer - UI UX Designer
                                        </label>
                                        <label>Posted by : 5 min ago</label>
                                        <div>
                                          <img src={loaction} alt="map" />
                                          <label className="ms-2">India</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right_sec">
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <p>
                                            {item?.description ||
                                              "No description available"}
                                          </p>
                                        </div>
                                        <div>
                                          <div>
                                            <img src={add} alt="add" />
                                          </div>
                                          <div className="mt-3">
                                            <img src={flag} alt="flag" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="skills-list mt-2 justify-content-start">
                                        {activeRole != "freelancer" ? (
                                          <ul>
                                            {item.skills &&
                                            item.skills.length > 0 ? (
                                              item.skills.map(
                                                (skill, skillIndex) => (
                                                  <li key={skillIndex}>
                                                    {skill}
                                                  </li>
                                                )
                                              )
                                            ) : (
                                              <li>No skills listed</li>
                                            )}
                                          </ul>
                                        ) : (
                                          <ul>
                                            {item.skillsNeeded &&
                                            item.skillsNeeded.length > 0 ? (
                                              item.skillsNeeded.map(
                                                (skill, skillIndex) => (
                                                  <li key={skillIndex}>
                                                    {skill}
                                                  </li>
                                                )
                                              )
                                            ) : (
                                              <li>No skills listed</li>
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                      <div className="list-grid-container">
                                        <div className="grid-item">
                                          <img
                                            src={reputationList}
                                            alt="reputation"
                                          />
                                          <div>
                                            <label>Reputation Score</label>
                                            <h6>
                                              <span>4.5</span>/5
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="grid-item">
                                          <img
                                            src={aiverifyList}
                                            alt="AI Verification"
                                          />
                                          <div>
                                            <label>AI Verification</label>
                                            <h6>
                                              <span>Verified</span>
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="grid-item">
                                          <img
                                            src={clientratingList}
                                            alt="Client Rating"
                                          />
                                          <div>
                                            <label>Client Rating</label>
                                            <h6>
                                              <span>4.5</span>(2547)
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="grid-item">
                                          <img src={amountList} alt="Amount" />
                                          <div>
                                            <label>Amount USD</label>
                                            <h6>
                                              <span>
                                                ${item.amountforFreelancer || 0}
                                              </span>
                                              /Hour
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="grid-item">
                                          <img src={payments} alt="Amount" />
                                          <div>
                                            <label>Payment Accepted</label>
                                            <div className="payment_iocns">
                                              {activeRole != "freelancer" ? (
                                                <>
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.freelcoin && (
                                                    <img
                                                      src={frel}
                                                      alt="freelcoin"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.traditional?.usd && (
                                                    <img
                                                      src={usd}
                                                      alt="usd"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.traditional?.inr && (
                                                    <img
                                                      src={inr}
                                                      alt="inr"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.traditional?.euro && (
                                                    <img
                                                      src={euro}
                                                      alt="euro"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.traditional?.pound && (
                                                    <img
                                                      src={pound}
                                                      alt="pound"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.other?.bitcoin && (
                                                    <img
                                                      src={bitcoin}
                                                      alt="bitcoin"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.other?.litecoin && (
                                                    <img
                                                      src={litecoin}
                                                      alt="litecoin"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.other?.ethereum && (
                                                    <img
                                                      src={ethereum}
                                                      alt="ethereum"
                                                      className="me-3"
                                                    />
                                                  )}
                                                  {item?.acceptedPaymentModes[0]
                                                    ?.other?.usdt && (
                                                    <img
                                                      src={usdt}
                                                      alt="usdt"
                                                      className="me-3"
                                                    />
                                                  )}
                                                </>
                                              ) : item.acceptedPaymentModes ===
                                                "FREL" ? (
                                                <img src={frel} alt="frel" />
                                              ) : item.acceptedPaymentModes ===
                                                "USD" ? (
                                                <img src={usd} alt="dollar" />
                                              ) : item.acceptedPaymentModes ===
                                                "INR" ? (
                                                <img src={inr} alt="inr" />
                                              ) : item.acceptedPaymentModes ===
                                                "EURO" ? (
                                                <img src={euro} alt="euro" />
                                              ) : item.acceptedPaymentModes ===
                                                "POUND" ? (
                                                <img src={pound} alt="pound" />
                                              ) : item.acceptedPaymentModes ===
                                                "BITCOIN" ? (
                                                <img
                                                  src={bitcoin}
                                                  alt="bitcoin"
                                                />
                                              ) : item.acceptedPaymentModes ===
                                                "LITECOIN" ? (
                                                <img
                                                  src={litecoin}
                                                  alt="litecoin"
                                                />
                                              ) : item.acceptedPaymentModes ===
                                                "ETHEREUM" ? (
                                                <img
                                                  src={ethereum}
                                                  alt="ethereum"
                                                />
                                              ) : (
                                                <img src={usdt} alt="usdt" />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))
                          ) : (
                            <div>
                              No 
                              {activeRole === "freelancer"
                                ? " projects "
                                : " services "}
                               available
                            </div>
                          )}
                        </Row>
                      </>
                    )}
                  </Row>
                </Card>
              </Col>

              <Col xs={12} sm={12} md={12} lg={5} xl={3}>
                <Card className="people_right_card">
                  <div>
                    <h5>By Skills</h5>
                    <div>
                      <Form.Control type="text" placeholder="Search" />
                    </div>
                    <div className="skills-list mt-2">
                      <ul>
                        <li>Web Dev</li>
                        <li>XD</li>
                        <li>Figma</li>
                        <li>Visual Designer</li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <h5>By Amount rage</h5>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <Range
                      step={STEP}
                      min={MIN}
                      max={MAX}
                      values={[
                        skillPayloadData.estimateBudget.min,
                        skillPayloadData.estimateBudget.max,
                      ]}
                      onChange={handleRangeChange}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            background: "#ddd",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props, index }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "16px",
                            width: "16px",
                            borderRadius: "50%",
                            backgroundColor: "#f0806c",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-28px",
                              color: "#555",
                              fontWeight: "bold",
                              fontSize: "12px",
                              background: "white",
                              padding: "2px 4px",
                              borderRadius: "3px",
                            }}
                          >
                            {index === 0
                              ? skillPayloadData.estimateBudget.min
                              : skillPayloadData.estimateBudget.max}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div>
                    <h5>Tier Level</h5>
                  </div>
                  <div className="skills-list mt-2">
                    <ul>
                      <li>Beginner</li>
                      <li>Emerging</li>
                      <li>Elite</li>
                      <li>standard</li>
                    </ul>
                  </div>
                  <div>
                    <h5>Mode of payment</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      className="custom-checkbox"
                      inline
                      label="FREL"
                      name="group1"
                      type="checkbox"
                    />
                    <Form.Check
                      className="custom-checkbox"
                      inline
                      label="CRYPTO"
                      name="group1"
                      type="checkbox"
                    />
                    <Form.Check
                      className="custom-checkbox"
                      inline
                      label="DOLLAR"
                      name="group1"
                      type="checkbox"
                    />
                  </div>
                  <div>
                    <h5>Availability</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      className="custom-checkbox"
                      inline
                      label="Yes"
                      name="group1"
                      type="radio"
                    />
                    <Form.Check
                      className="custom-checkbox"
                      inline
                      label="No"
                      name="group1"
                      type="radio"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default MyWorks;
