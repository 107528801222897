// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project_preview_left .card{
    border-radius: 30px;
    padding: 20px;
    background-color: #FCFCFC !important;
    box-shadow: 0px 4px 8px 0px #0000000F;
    border: 1px solid #EEEAEA;
}
.project_preview_right .card{
    background: #F4F4F4;
    border: 1px solid #EEEAEA;
    border-radius: 30px;
    padding: 30px;
}
.project_preview_right .card h5{
    font-size: 18px;
}
.coverphoto-preview img{
    border-radius: 25px;
    border: 1px solid #dddddd75;
    height:150px;
}
.image-caurosel img{
    height:400px;
    border-radius: 30px;

}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Project/ProjectPreview.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,oCAAoC;IACpC,qCAAqC;IACrC,yBAAyB;AAC7B;AACA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,YAAY;AAChB;AACA;IACI,YAAY;IACZ,mBAAmB;;AAEvB","sourcesContent":[".project_preview_left .card{\r\n    border-radius: 30px;\r\n    padding: 20px;\r\n    background-color: #FCFCFC !important;\r\n    box-shadow: 0px 4px 8px 0px #0000000F;\r\n    border: 1px solid #EEEAEA;\r\n}\r\n.project_preview_right .card{\r\n    background: #F4F4F4;\r\n    border: 1px solid #EEEAEA;\r\n    border-radius: 30px;\r\n    padding: 30px;\r\n}\r\n.project_preview_right .card h5{\r\n    font-size: 18px;\r\n}\r\n.coverphoto-preview img{\r\n    border-radius: 25px;\r\n    border: 1px solid #dddddd75;\r\n    height:150px;\r\n}\r\n.image-caurosel img{\r\n    height:400px;\r\n    border-radius: 30px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
