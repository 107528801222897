import { useState } from "react";
import MyMessages from './MyMessages';
import PublishedMessages from './PublishedMessages';
import DraftMessages from './DraftMessages';
import "./MyService.css"


const MyProject = ({ role }) => {
  const [activeButton, setActiveButton] = useState("myMessages");

  // console.log(role,"role in myservice");
  

  const renderContent = () => {
    switch (activeButton) {
      case "myMessages":
        return <MyMessages role={role} />;
      
      case "published":
        return <PublishedMessages role={role} />;
      
      case "drafts":
        return <DraftMessages role={role} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="msg-button-group my-4">
        <button onClick={() => setActiveButton("myMessages")} className={activeButton === "myMessages" ? "active" : ""}>My Messages</button>
        <button onClick={() => setActiveButton("published")} className={activeButton === "published" ? "active" : ""}>Published</button>
        <button onClick={() => setActiveButton("drafts")} className={activeButton === "drafts" ? "active" : ""}>Drafts</button>
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default MyProject;
