import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import profile from "../../../../Images/profile.png";
import dollar from "../../../../Images/dollar.svg";
import deleteIcon from "../../../../Images/delete.png";

import "./Messages.css";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import { useViewContext } from "../../../../Context/ViewContext";

const DraftMessages = ({ role }) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleShowDrafts } = useViewContext();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (role) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [role]);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `service/user?userId=${userId}&status=draft`
          : `project/user?userId=${userId}&status=draft`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleGetDetails = async (id) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer" ? `service/${id}` : `project/${id}`;
      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        handleShowDrafts(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="draft_messages">
        <div>
          <h3>My Drafts</h3>
        </div>
        <p>
          Lorem ipsum is a dummy or placeholder text commonly used in graphic
          design
        </p>
        <div></div>
        <Row>
          {activeRoleDetails && activeRoleDetails.length > 0 ? (
            activeRoleDetails.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                className="mt-3"
                key={index}
              >
                <Card
                  className="proposals_card"
                  onClick={() =>
                    handleGetDetails(
                      role === "freelancer" ? item.serviceId : item.projectId
                    )
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <div className="profile_pic me-3">
                        <img src={item.profilePhoto} alt="profile" />
                      </div>
                      <div>
                        <h5 className="mb-0">{item.firstName}</h5>
                        <label>India</label>
                      </div>
                    </div>
                    <div>
                      <span>Draft</span> <img src={deleteIcon} alt="delete" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <img src={dollar} alt="dollar" />
                      </div>
                      <div className="ms-3">
                        <label>
                          Paid in
                          <br />
                          US Dollar
                        </label>
                      </div>
                    </div>
                    <div className="text-end me-3">
                      <label>Bid amount</label>
                      <h6>$20/hour</h6>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div>No Draft services available</div>
          )}
        </Row>
      </div>
    </>
  );
};

export default DraftMessages;
