import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import profile from "../../../../Images/profile.png";
import dollar from "../../../../Images/dollar.svg";
import deleteIcon from "../../../../Images/delete.png";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import ApiCall from "../../../../Api/ApiCall";
import Form from "react-bootstrap/Form";
import { useViewContext } from "../../../../Context/ViewContext";


const MyBids = () => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleShowMyBids } = useViewContext();


  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET", 
        `bid/user?userId=${userId}&role=client`,  
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getProposalDetailsByStage = async (stage) => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint = `bid/filterByStatus?freelancerId=${userId}&stage=${stage}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}

      

      <div className="draft_messages">
        

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3>My Bids</h3>
          </div>
          <div>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                const stage = e.target.value;
                if (stage !== "Select") {
                  getProposalDetailsByStage(stage);
                }
                else{
                  getActiveRoleDetails()
                }
              }}
            >
              <option>Select</option>
              <option value="accepted">Accepted</option>
              <option value="inProgress">Inprogress</option>
              <option value="rejected">Rejected</option>
            </Form.Select>
          </div>
        </div>

        <p>
            Lorem ipsum is a dummy or placeholder text commonly used in graphic
            design
          </p>

        <Row>
          {activeRoleDetails && activeRoleDetails.length > 0 ? (
            activeRoleDetails.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                className="mt-3"
                key={index}
              >

                <Card className="proposals_card" onClick={handleShowMyBids} >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <div className="profile_pic me-3">
                        <img src={item.profilePhoto} alt="profile" />
                      </div>
                      <div>
                        <h5 className="mb-0">{item.firstName}</h5>
                        <label>United States</label>
                      </div>
                    </div>
                    <div>
                      <img src={deleteIcon} alt="delete" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <h5>{item.title}</h5>
                    <p>{item.message}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <img src={dollar} alt="dollar" />
                      </div>
                      <div className="ms-3">
                        <label>
                          Paid in
                          <br />
                          US Dollar
                        </label>
                      </div>
                    </div>
                    <div className="me-3">
                      <label>Bid amount</label>
                      <h6>${item.bidAmount}</h6>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div>No Bids available</div>
          )}
        </Row>
      </div>
    </>
  );
};

export default MyBids;
