import React from 'react'
import MyCustomCalendar from '../../../Common/MyCustomCalendar'

const MyCalendar = ({ role }) => {
  return (
    <>
      {role === "client" ? (
        <div>My client MyCalendar</div>
      ) : (
        // <div> My freelancer MyCalendar</div>
        <MyCustomCalendar/>
      )}
    </>
  )
}

export default MyCalendar

