import React, { useEffect, useRef, useState } from "react";
import { Row, Col,Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { debounce } from "lodash";
import useToast from "../../../Hooks/useToast";
import Loader from "../../Common/Loader/Loader";
import ApiCall from "../../../Api/ApiCall";
import uploadIconSvg from "../../../Images/icons/upload.svg";
import pdfIcon from "../../../Images/icons/pdf-icon.svg";
import profilePhoto from "../../../Images/profile.png"
import "./PlaceBid.css"
import { useNavigate } from "react-router-dom";

const AskProposal = ({serviceId,freelancerId, clientId,userId,profilePhoto,firstName}) => {
  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const [certificateFile, setCertificateFile] = useState(null);
  const certiInputRef = useRef(null);
  const navigate = useNavigate();

  const [payloadData, setPayloadData] = useState({
    projectId: "",
    requestMessage: "",
    certificateFile: [],
  });

  const handleUploadClick = (fileInputRef) => {
    fileInputRef?.current?.click(); // Trigger the hidden file input click
  };

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    debouncedGetActiveRoleDetails();

    return () => debouncedGetActiveRoleDetails.cancel();
  }, []);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const response = await ApiCall(
        "GET",
        `project/user?userId=${userId}&status=final`,
        null,
        "application/json"
      );

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoChange = async (e, setPhoto, field) => {
    console.log(e, "e");
    setLoading(true);

    const file = e.target.files[0]; 
    if (file) {
      setPhoto(file);
      try {
       
        const formData = new FormData();
        formData.append("file", file);

       
        const response = await ApiCall(
          "POST",
          `user/fileUpload`,
          formData,
          "multipart/form-data"
        );

        if (response.statusCode === 200) {
         
          setPayloadData((prevState) => ({
            ...prevState,
            [field]: [...(prevState[field] || []), response.data.s3Url], 
          }));
        } else {
          // Handle upload failure
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayloadData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  console.log(payloadData,"payloadDatapayloadDatapayloadData");
  

  const handleSubmitProposal = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(payloadData, "payloadsadda")
    try {
      const payload = {

          serviceId: serviceId ,
          projectId: payloadData?.projectId,
          clientId: clientId,
          freelancerId: freelancerId,
          requestMessage: payloadData?.requestMessage,
          attachments: payloadData?.certificateFile,
          isRead: false,
     
      };

      // console.log(payload, "proposal post payload");

      const response = await ApiCall(
        "POST",
        "proposal/createProposal",
        payload,
        "application/json"
      );
      // Handle success with 200 status code
      if (response.statusCode === 201) {
        console.log("successfuldfcgvh:", response.data);
        notify("success", "Proposal asked successfully!");
        navigate("/my-works");
      } else {
        notify(
          "error",
          response.message || "Proposal failed. Please try again."
        );
      }
    } catch (error) {
      // Handle API call error
      console.error("Login error:", error);
      notify("error", error.response.data.message);
    } finally {
      // Set loading to false once the request is complete
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="ask-proposal p-4">        
        <div>
          <h3>Send a Message</h3>
        </div>
          <form className="bid-form">
            <div className="d-flex align-items-center my-3">
              <img src={profilePhoto} alt="profile" className="prof_pic" />
              <h6 className="mb-0 ms-3">{firstName}</h6>
            </div>
              <div>
                <label>Select Project<span className="text-danger">*</span></label>
                <Form.Select
                  aria-label="Default select example"
                  name="projectId"
                  value={payloadData.projectId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Project</option>
                  {activeRoleDetails.map((service, index) => (
                    <option key={index} value={service.projectId}>
                      {service.title}
                    </option>
                  ))}
                </Form.Select>
              </div>
         

       
              <Form.Group className="mb-3 mt-2 bid-text">
                <label>Message</label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="message"
                  name="requestMessage"
                  value={payloadData.requestMessage}
                  onChange={handleInputChange}
                  className="bio-textarea"
                  placeholder="Type message here..."
                />
              </Form.Group>
       

         
              <div className="border-0 d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center">
                  {certificateFile ? (
                    <div className="file-preview">
                      <div className="file-details">
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          className="file-icon"
                        />
                        <div className="file-info ms-3">
                          <p className="file-title mb-0">
                            {certificateFile.name}
                          </p>
                          <p className="file-meta mb-0">
                            {certificateFile.size} KB ·{" "}
                            {new Date(
                              certificateFile.lastModified
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <img
                          src={uploadIconSvg}
                          alt="Upload Icon"
                          width="40px"
                          height="40px"
                          onClick={() => handleUploadClick(certiInputRef)}
                        />
                        <input
                          type="file"
                          accept=".pdf,.docx"
                          style={{ display: "none" }}
                          ref={certiInputRef}
                          onChange={(e) =>
                            handlePhotoChange(
                              e,
                              setCertificateFile,
                              "certificateFile"
                            )
                          }
                        />
                        {/* <label>
                          Please upload your documents (Doc , Pdf..etc)
                        </label> */}
                      </div>
                      {/* <div>
                        <button
                          type="button"
                          className="secondary-btn"
                          onClick={() => handleUploadClick(certiInputRef)}
                        >
                          Choose
                        </button>
                        <input
                          type="file"
                          accept=".pdf,.docx"
                          style={{ display: "none" }}
                          ref={certiInputRef}
                          onChange={(e) =>
                            handlePhotoChange(
                              e,
                              setCertificateFile,
                              "certificateFile"
                            )
                          }
                        />
                      </div> */}
                    </>
                  )}
                </div>
                <div className="text-center my-4">
                  <button type="submit" className="primary-btn py-2 px-5" onClick={handleSubmitProposal}>
                    Send
                  </button>
                </div>
              </div>
           

          
          </form>
      </div>
    </>
  );
};

export default AskProposal;
