import { ViewProvider } from "../../../Context/ViewContext";
import FindWorksContent from "./FindWorksContent";

const FindWorks = () => {
  return (
    <ViewProvider>
      <FindWorksContent />
    </ViewProvider>
  );
};

export default FindWorks;
