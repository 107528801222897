import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import profile from "../../../../Images/profile.png"
import dollar from "../../../../Images/dollar.svg"
import deleteIcon from "../../../../Images/delete.png"


const SavedBids = () => {
  return (
    <div className="draft_messages">
      
      <div className='my-4'>
        <h3>Saved Bids</h3>
        <p>Lorem ipsum is a dummy or placeholder text commonly used in graphic design</p>
      </div>
    
    <div>
    
    </div>
    <Row>
      <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-3">
      <Card className="proposals_card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="profile_pic me-3">
              <img src={profile} alt="profile"/>
            </div>
            <div>
              <h5 className="mb-0">Rakesh Kumar</h5>
              <label>United States</label>
            </div>
            </div>
            <div>
            <img src={deleteIcon} alt="delete" />
           </div>
        </div>
        <div className="mt-3">
          <h5>Website Design</h5>
          <p>Lorem ipsum is a dummy or placeholder text commonly used in graphic design</p>
        </div>
        <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div>
            <img src={dollar} alt="dollar" />
            </div>
            <div className="ms-3">
              <label>Paid in<br/>US Dollar</label>
            </div>
        </div>
        <div className="me-3">
          <label>Bid amount</label>
          <h6>$40</h6>
        </div>
        </div>
      </Card>
      </Col>      
    </Row> 
  
   </div>
  )
}

export default SavedBids