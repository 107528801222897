import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import profile from "../../../../Images/profile.png";
import deleteIcon from "../../../../Images/delete.png";
import vector from "../../../../Images/vector.png";
import "./Messages.css";
import ApiCall from "../../../../Api/ApiCall";
import useToast from "../../../../Hooks/useToast";
import Loader from "../../../Common/Loader/Loader";
import { debounce } from "lodash";
import { useViewContext } from "../../../../Context/ViewContext";

const MyMessages = ({ role }) => {
  // console.log(role, "role in mymsg");

  const [loading, setLoading] = useState(false);
  const [activeRoleDetails, setActiveRoleDetails] = useState([]);
  const { notify } = useToast();
  const userId = localStorage.getItem("userId");
  const { handleViewProposal } = useViewContext();

  const debouncedGetActiveRoleDetails = debounce(() => {
    getActiveRoleDetails();
  }, 300);

  useEffect(() => {
    if (role) {
      debouncedGetActiveRoleDetails();
    }
    return () => debouncedGetActiveRoleDetails.cancel();
  }, [role]);

  const getActiveRoleDetails = async () => {
    setLoading(true);
    setActiveRoleDetails([]);
    try {
      const endpoint =
        role === "freelancer"
          ? `proposal/user/${userId}`
          : `proposal/client/${userId}`;
      // console.log(endpoint, "endpoint");

      const response = await ApiCall("GET", endpoint, null, "application/json");

      if (response.statusCode === 200) {
        setActiveRoleDetails(response.data);
      } else {
        notify("error", response.message);
      }
    } catch (error) {
      notify("error", error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  // console.log(activeRoleDetails, "activeRoleDetails");

  return (
    <>
      {loading && <Loader />}
      <div className="my_messages">
        <div>
          <h5>My Messages</h5>
          <p>
            Lorem ipsum is a dummy or placeholder text commonly used in graphic
            design
          </p>
        </div>

        <Row>
          {activeRoleDetails && activeRoleDetails.length > 0 ? (
            activeRoleDetails.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                className="mt-3"
                key={index}
              >
                <Card className="proposals_card msg-card">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <div className="profile_pic me-3">
                        <img src={item?.profilePhoto} alt="profilePhoto" />
                      </div>
                      <div className="name_sec">
                        <h5 className="mb-0">
                          {role === "freelancer"
                            ? item.clientName
                            : item.firstName}
                        </h5>
                        <label>India</label>
                      </div>
                    </div>
                    <div className="text-end">
                      <label>Project Budget</label>
                      <h6>
                        $
                        {role === "freelancer"
                          ? item.estimateBudget.min
                          : item.estimateBudget.min}
                        - ${" "}
                        {role === "freelancer"
                          ? item.estimateBudget.max
                          : item.estimateBudget.max}
                      </h6>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      {role === "freelancer"
                        ? item.requestMessage
                        : item.requestMessage}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <img src={deleteIcon} alt="delete" />
                    </div>
                    <div className="ms-3 card_bottom_sec">
                      <label>
                        <img src={vector} alt="attach" className="me-2" />
                        {role === "freelancer"
                          ? item.attachments.length
                          : item.attachments.length}
                        files
                      </label>
                      <label
                        className="ms-3"
                        onClick={() => handleViewProposal(item.projectId)}
                      >
                        View Proposal
                      </label>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div>No Messages available</div>
          )}
        </Row>
      </div>
    </>
  );
};

export default MyMessages;
